<template>
  <a-card
    ref="card"
    :header="$t('common.replacement')"
    :editable="Boolean(doc.replacement)"
  >
    <template
      #header-buttons
    >
      <b-icon
        v-if="doc.replacement"
        icon="minus-circle-outline"
        @click.native="removeReplacement"
      />

      <b-icon
        v-else
        icon="plus-circle-outline"
        @click.native="addReplacement"
      />
    </template>

    <template>
      <div
        v-if="doc.replacement"
      >
        <a-value
          field="replacement.part.name"
          :label="$t('failureParts.columns.replacement.part.name')"
        />

        <a-value
          field="replacement.part.supplier.name"
          :label="$t('failureParts.columns.replacement.part.supplier.name')"
        />

        <a-value
          field="replacement.number"
          :label="$t('failureParts.columns.replacement.number')"
        >
          <code>{{ doc.replacement.number }}</code>
        </a-value>

        <a-value
          field="replacement.revision"
          :label="$t('failureParts.columns.replacement.revision')"
        />
      </div>
      <div v-else>
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon
              icon="emoticon-sad"
              size="is-large"
            />
          </p>
          <p><slot>{{ $t('common.no_replacement') }}</slot></p>
        </div>
      </div>
    </template>

    <template
      #form
    >
      <a-select
        field="replacement.part"
        :label="$t('failureParts.columns.replacement.part.name')"
        options="parts"
        option-label="name"
        :to-pick="['id', 'name', 'supplier.id', 'supplier.name']"
      />

      <a-input
        field="replacement.number"
        :label="$t('failureParts.columns.replacement.number')"
      />

      <a-input
        field="replacement.revision"
        :label="$t('failureParts.columns.replacement.revision')"
      />
    </template>
  </a-card>
</template>

<script>
export default {
  methods: {
    addReplacement () {
      const value = { part: _.assign({}, this.doc.part), number: null }
      this.$store.dispatch('currentDoc/set', {
        field: 'replacement',
        value
      })
      this.$refs.card.isEditing = true
    },
    removeReplacement () {
      this.$store.dispatch('currentDoc/set', {
        field: 'replacement',
        value: null
      })
    }
  }
}
</script>
